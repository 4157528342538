import axios from "axios";


var baseURL='';

   
          const hostname = window.location.hostname;
  
          const subdomain = hostname.split('.')[0];
    
          if (subdomain === 'www') {
			baseURL = `https://www.admin.codewithjtc.com/jtc/${process.env.REACT_APP_API_DOMAIN}`;
          } else{
            baseURL = `https://admin.codewithjtc.com/jtc/${process.env.REACT_APP_API_DOMAIN}`;
          }

export const instance = axios.create({ baseURL : baseURL });
instance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
instance.defaults.withCredentials = true;
